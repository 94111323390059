import {useState, useContext} from 'react';

import {
    CompanyLogo,
    HeaderNavMenu,
    HeaderMainMenu,
    HeaderTop,
    MainMenu,
    ScrollToTop,
} from '@/components/export';

import appContext from '@/appContext.tsx';

export const Header = () => {
    const {currentUser} = useContext(appContext).userContext;

    const [showMainMenu, setShowMainMenu] = useState(false);

    return <>
        <ScrollToTop />
        <div className="header-wrapper">
            <HeaderTop />

            <div className="header-main">
                <div className="header-main__container container">
                    <CompanyLogo/>

                    <div className="header-main__nav">
                        <CompanyLogo/>

                        {currentUser.id ? <HeaderNavMenu/> : <></>}
                    </div>
                    {
                        currentUser.id ?
                            <HeaderMainMenu
                                onToggleMenu={() => setShowMainMenu(!showMainMenu)}
                                active={showMainMenu}
                            /> :
                            <></>
                    }
                </div>
            </div>
        </div>
        {
            showMainMenu ?
                <MainMenu onClick={() => setShowMainMenu(false)} /> :
                <></>
        }
    </>;
};
