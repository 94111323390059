import utils from 'utils';

import {useContext, useRef, useEffect} from 'react';

import appContext from '@/appContext';

export const ProductSearch = () => {
    const {filters, updateFilters} = useContext(appContext).productsContext;

    const inputRef = useRef(null);
    const timeoutRef = useRef(null);

    const searchPlaceholderText = 'např.: 12165 (12-16.5)';

    const handle = {
        inputChange: (value: string) => {
            utils.doWithTimeout(() => {
                updateFilters({
                    name: value,
                    offset: 0,
                });

                window.scroll(0, 0);
            }, 500, timeoutRef);
        },
    };

    useEffect(() => {
        if (filters.name !== undefined) {
            inputRef.current.value = filters.name;
        }
    }, [filters.name]);

    return <div className={cmpClass + (filters.name ? ' ' + cmpClass + '--active' : '')}>
        <input
            onChange={(e) => handle.inputChange(e.target.value)}
            placeholder={searchPlaceholderText}
            type="text"
            className={classMap.input}
            defaultValue={filters.name}
            ref={inputRef}
        />
        <i className={classMap.iconSearch}></i>
    </div>;
};

const cmpClass = 'product-search';
const classMap = utils.generateClassMap(cmpClass, {
    input: 'input',
    inputActive: 'input--active',
    iconSearch: 'icon iconfa-search',
});
